import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { InteractiveHighlighter as IH } from "react-interactive-highlighter";

import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/abhaya-libre";

import Button from "~/components/button";
import UnorderedList from "~/components/List/Unordered";

import contentJSON from "./basics-page-content.json"
import HighlightedContent from "./highlighted-content";


type SelectionHandler = (selected: string, startIndex: number, numChars: number) => void;

const selectionHandler = (
  setH: Dispatch<SetStateAction<Highlight[]>>,
  setP: Dispatch<SetStateAction<string | undefined>>,
  p: string | undefined
): SelectionHandler => {
  // @ts-ignore
  return (selected: string, startIndex: number, numChars: number): void => {
    setH(current => {
      const newHighlight: Highlight = { startIndex, numChars };

      return [...current, newHighlight];
    });
    setP(p);
  };
};

interface Highlight {
  numChars: number;
  startIndex: number;
};

interface Content {
  hClasses?: string;
  headingText?: string;
  highlightedText?: string;
  pClasses?: string;
  paragraphText?: string;
  highlighterList?: ListContent;
}

interface HighlighterBlockProps extends Content {
  clearHighlights: boolean;
}

interface formattedListElement {
  headingText?: string;
  headingClassName?: string;
  bodyText?: string
}

interface ListContent {
  listElements?: formattedListElement[];
  listHeading?: string;
}

interface HighlighterListProps extends ListContent {
  clearHighlights: boolean;
}

const HighlighterList = ({
  clearHighlights,
  listElements,
  listHeading,
}: HighlighterListProps): JSX.Element => {
  const [lH, setLH] = useState<string | undefined>(listHeading);
  const [lHH, setLHH] = useState<Highlight[]>([]);

  const listHeadingSelectionHandler: SelectionHandler = selectionHandler(setLHH, setLH, lH);
  const highlighterColor: string = "bg-yellow-500";

  useEffect(() => {
    setLHH([]);
    setLH(listHeading);
  }, [clearHighlights]);

  return (
    <>
      {listHeading &&
        <p>
          <IH
            text={listHeading}
            highlights={lHH}
            customClass={highlighterColor}
            selectionHandler={listHeadingSelectionHandler}
          />
        </p>
      }
      {listElements &&
        <UnorderedList>
          {listElements.map((elem: formattedListElement, index: number) => {
            const [list, setList] = useState<string | undefined>(elem.bodyText);
            const [listHighlights, setListHighlights] = useState<Highlight[]>([]);

            const [heading, setHeading] = useState<string | undefined>(elem.headingText);
            const [headingHighlights, setHeadingHighlights] = useState<Highlight[]>([]);

            const listSelectionHandler: SelectionHandler = selectionHandler(setListHighlights, setList, list);
            const headingSelectionHandler: SelectionHandler = selectionHandler(setHeadingHighlights, setHeading, heading);

            useEffect(() => {
              setListHighlights([]);
              setHeadingHighlights([]);
              setHeading(elem.headingText);
              setList(elem.bodyText);
            }, [clearHighlights]);

            return (
              <li>
                {elem.headingText &&
                  <span className={elem?.headingClassName}>
                    <IH
                      customClass={highlighterColor}
                      highlights={headingHighlights}
                      key={`highlight-li-header-${index}`}
                      selectionHandler={headingSelectionHandler}
                      text={elem.headingText}
                    />
                  </span>
                }
                {elem.bodyText &&
                  <IH
                    customClass={highlighterColor}
                    highlights={listHighlights}
                    key={`highlight-li-body-${index}`}
                    selectionHandler={listSelectionHandler}
                    text={elem.bodyText}
                  />
                }
              </li>
            );
          })}
        </UnorderedList>
      }
    </>
  );
}

const HighlighterBlock = ({
  clearHighlights,
  hClasses,
  headingText,
  highlightedText,
  highlighterList,
  pClasses,
  paragraphText,
}: HighlighterBlockProps): JSX.Element => {
  const [p, setP] = useState<string | undefined>(paragraphText);
  const [h, setH] = useState<string | undefined>(headingText);

  const [pH, setPH] = useState<Highlight[]>([]);
  const [hH, setHH] = useState<Highlight[]>([]);

  const headingSelectionHandler: SelectionHandler = selectionHandler(setHH, setH, h);
  const paragraphSelectionHandler: SelectionHandler = selectionHandler(setPH, setP, p);

  const headingHighlighter: string = "bg-indigo-200";
  const paragraphHighlighter: string = "bg-yellow-500";

  const headingClasses: string = hClasses ? hClasses : "font-bold";
  const highlightedClasses: string = hClasses ? hClasses : "bg-indigo-200 font-bold";
  const paragraphClasses: string = pClasses ? pClasses : "";

  useEffect(() => {
    setPH([]);
    setP(p);
    setHH([]);
    setH(h);
  }, [clearHighlights]);

  return (
    <div className="mt-5">
      {headingText &&
        <h2 className={headingClasses}>
          <IH
            text={headingText}
            highlights={hH}
            customClass={headingHighlighter}
            selectionHandler={headingSelectionHandler}
          />
        </h2>
      }
      {highlightedText &&
        <h2>
          <span className={highlightedClasses}>
            <IH
              text={highlightedText}
              highlights={hH}
              customClass={headingHighlighter}
              selectionHandler={headingSelectionHandler}
            />
          </span>
        </h2>
      }
      {paragraphText &&
        <p className={paragraphClasses}>
          <IH
            text={paragraphText}
            highlights={pH}
            customClass={paragraphHighlighter}
            selectionHandler={paragraphSelectionHandler}
          />
        </p>
      }
      {highlighterList &&
        <HighlighterList
          listHeading={highlighterList?.listHeading}
          listElements={highlighterList?.listElements}
          clearHighlights={clearHighlights}
        />
      }
    </div>
  );
}

const Page = (): JSX.Element => {
  const [clearHighlights, setClearHighlights] = useState<boolean>(false);
  const [compareHighlights, setCompareHighlights] = useState<boolean>(false);

  return (
    <div className="bg-gray-100 text-gray-800 p-10">
      <div style={{ fontFamily: "Abhaya Libre" }}>
        <h1 className="text-4xl w-full text-center">NIH Clinical Research Trials and You</h1>
      </div>

      <div className="flex justify-end mt-5 mb-8">
        <Button
          action="secondary"
          color="indigo"
          onClick={() => setClearHighlights(!clearHighlights)}
          title="Clear highlights"
        >
          Clear highlights
        </Button>

        <Button
          action="primary"
          className="ml-5"
          color="indigo"
          onClick={() => setCompareHighlights(!compareHighlights)}
          title="Compare Highlights"
        >
          Compare Highlights
        </Button>
      </div>

      <div className="flex flex-col space-y-2 md:flex-row md:space-y-0">
        <div className={compareHighlights ? "w-full md:w-1/2 md:pr-3" : undefined}>
          {compareHighlights &&
            <>
              <h2 className="font-bold text-3xl after:block after:bg-indigo-600 after:w-8 after:h-1 after:rounded">Your highlights</h2>
            </>
          }
          {contentJSON.content && contentJSON.content.map((value: Content, idx: number) => {
            // each JSON object gets put into a HighlighterBlock, images are conditionally
            // rendered based on paragraph number
            // I chose to not render images on the compare view because it makes
            // it more difficult to compare.
            return (
              <>
                <HighlighterBlock
                  clearHighlights={clearHighlights}
                  hClasses={value?.hClasses}
                  headingText={value?.headingText}
                  highlightedText={value?.highlightedText}
                  highlighterList={value?.highlighterList}
                  pClasses={value?.pClasses}
                  paragraphText={value?.paragraphText}
                />
                {idx === 1 && !compareHighlights &&
                  <>
                    <StaticImage
                      className="mt-5"
                      alt="Clinician talking to patient"
                      loading="eager"
                      src="../../../../../images/clinician-patient.png"
                      placeholder="blurred"
                    />
                    <p className="text-gray-500">stevecoleimages/iStock</p>
                  </>
                }
                {idx === 10 && !compareHighlights &&
                  <StaticImage
                    className="mt-5"
                    alt="Information on observational studies versus clinical trails"
                    loading="lazy"
                    src="../../../../../images/clinical-studies.png"
                    placeholder="blurred"
                  />
                }
                {idx === 32 && !compareHighlights &&
                  <>
                    <StaticImage
                      className="mt-5"
                      alt="A couple hugging and smiling"
                      loading="lazy"
                      src="../../../../../images/couple-smiling.png"
                      placeholder="blurred"
                    />
                    <p className="text-gray-500">Mark Bowden / iStock</p>
                  </>
                }
                {idx === 39 && !compareHighlights &&
                  <>
                    <StaticImage
                      className="mt-5"
                      alt="Family picture where everyone is happy"
                      loading="lazy"
                      src="../../../../../images/happy-family.png"
                      placeholder="blurred"
                    />
                    <p className="text-gray-500">monkeybusinessimages/iStock</p>
                  </>
                }
              </>
            );
          })}
        </div>
        {
          compareHighlights &&
          <div className={compareHighlights ? "w-full md:w-1/2 md:pl-3 bg-gray-200 rounded-r" : undefined}>
            <h2 className="font-bold text-3xl after:block after:bg-indigo-600 after:w-8 after:h-1 after:rounded">Teacher highlights</h2>
            <HighlightedContent />
          </div>
        }
      </div>
    </div>
  );
};

export default Page;

